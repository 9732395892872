import type { ComponentPropsWithoutRef } from "react";

import { Card } from "~/components/ui/cards/card";
import { Image } from "~/components/ui/image";
import { Heading, Text } from "~/components/ui/typography";
import { cn } from "~/utils/classnames";

export function CommonCard({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<typeof Card>) {
  return (
    <Card className={cn("relative flex flex-col overflow-hidden p-6 pt-0", className)} {...props}>
      <div className="mt-44" />
      {children}
    </Card>
  );
}

export function CommonCardImage({ className, ...props }: ComponentPropsWithoutRef<typeof Image>) {
  return (
    <Image
      className={cn("absolute left-0 right-0 top-0 h-44 w-full object-cover", className)}
      {...props}
    />
  );
}

export function CommonCardTitle({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<typeof Heading>) {
  return (
    <Heading size="lg" className={cn("pt-4", className)} {...props}>
      {children}
    </Heading>
  );
}

export function CommonCardDescription({
  as,
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<typeof Text>) {
  return (
    <Text as={as || "p"} size="sm" className={cn("mt-2 grow", className)} {...props}>
      {children}
    </Text>
  );
}

export function CommonCardButtons({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<"div">) {
  return (
    <div className={cn("mt-4 flex flex-col-reverse gap-3 sm:flex-row", className)} {...props}>
      {children}
    </div>
  );
}
