import Autoplay from "embla-carousel-autoplay";
import type { ComponentPropsWithoutRef, ReactNode } from "react";

import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
} from "~/components/ui/carousels/carousel";
import { cn } from "~/utils/classnames";

interface CommonCardProps extends ComponentPropsWithoutRef<"div"> {
  items: ReactNode[];
}

export function CommonCardCarousel({ items, className }: CommonCardProps) {
  return (
    <Carousel
      opts={{
        align: "center",
        breakpoints: {
          "(min-width: 640px)": { align: "start" },
        },
      }}
      plugins={[
        Autoplay({
          delay: 5000,
          stopOnInteraction: false,
          stopOnMouseEnter: true,
        }),
      ]}
      className={className}
    >
      <CarouselContent className="-mx-1 py-2">
        {items && items?.length > 0
          ? items.map((item, index) => (
              <CarouselItem
                key={index}
                className={cn(
                  "px-2 *:h-full md:basis-1/2 lg:basis-1/3",
                  items.length === 3 && index === 0 ? "xl:basis-1/2" : "xl:basis-1/4"
                )}
              >
                {item}
              </CarouselItem>
            ))
          : null}
      </CarouselContent>

      <CarouselDots />
    </Carousel>
  );
}
